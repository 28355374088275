.auth-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}

.auth-content {
  padding: 112px 0 24px 0;
}

.auth-main {
  width: 95%;
  margin: auto;
}

@media (min-width: 1024px) {
  .auth-container {
    background-image: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }

  .auth-content {
    padding: 32px 0;
    flex: 1;
  }

  .auth-main {
    width: 368px;
    margin: 0 auto;
  }
}
